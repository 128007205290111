<template>
  <div>
    <div class="air__utils__heading">
      <h5>Inicio</h5>
    </div>

    <div class="row">
      <div class="col-xl-2 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
                <router-link :to="{ name: 'service_plannings'}">
                  <strong> <b-icon icon="calendar3"/></strong>
                </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'service_plannings'}">
              <h6 class="text-block text-secondary">Planeación de Servicios</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'vigilance'}">
                <strong> <b-icon icon="lock-fill"/> </strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'vigilance'}">
              <h6 class="text-block text-secondary text-capitalize">Vigilancia</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'operations'}">
                <strong><b-icon icon="truck-flatbed"/></strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'operations'}">
              <h6 class="text-block text-secondary text-capitalize">Operaciones</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'laboratories'}">
                <strong>  <b-icon icon="calculator-fill"/> </strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'laboratories'}">
              <h6 class="text-block text-secondary text-capitalize">Laboratorio</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'administrations'}">
                <strong> <b-icon icon="folder-fill"/></strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'administrations'}">
            <h6 class="text-block text-secondary text-capitalize">Administración</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'reports'}">
                <strong><b-icon icon="graph-up"/></strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'reports'}">
              <h6 class="text-block text-secondary text-capitalize">Resultados</h6>
            </router-link>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import 'moment/locale/es'
import moment from 'moment'
export default {
  name: 'KitAntdCollapseExample',
  data() {
    return {
      dated_at: moment().format('MMMM Do YYYY, h:mm:ss a'),
    }
  },
  components: {

  },
}
</script>
